import React, { useState } from 'react';
import Typed from 'typed.js';

export default function Intro() {
  const el = React.useRef(null);
  const typed = React.useRef(null);
  React.useEffect(() => {
    const options = {
      strings: [
        'SoftwareEngineer <span style="color: #ba7cab">zain</span> = <span style="color: #baa27c">new</span> SoftwareEngineer()&#59;',
        'const <span style="color: #ba7cab">zain</span> = <span style="color: #baa27c">new</span> SoftwareEngineer()&#59;',
        '<span style="color: #ba7cab">zain</span> = SoftwareEngineer.<span style="color: #baa27c">new</span>',
      ],
      typeSpeed: 30,
      backSpeed: 30,
      backDelay: 2500,
      loop: true,
    };
    typed.current = new Typed(el.current, options);
    return () => {
      typed.current.destroy();
    };
  }, []);
  return (
    <div>
      <p className="commented">
            /* welcome to my #3a3153 corner of the internet. i'm currently a
            software engineering student at the university of waterloo. */
      </p>
      <div className="container">
        <span className="declare" ref={el} />
      </div>
    </div>
  );
}
