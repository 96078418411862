import React from 'react';

const containerStyle = {
  borderRadius: '999px',
  backgroundColor: '#444054',
  padding: '4px 8px',
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
}

const iconStyle = {
  height: '1rem',
  width: '1rem',
  marginRight: '4px',
}

const textStyle = color => {return {
  fontSize: '10px',
  color: color,
}}

export default function Badge(props){
  return (
    <span style={containerStyle}>
      <img src={props.icon} style={iconStyle} />
      <span style={textStyle(props.color)}>{props.text}</span>
    </span>
  )
}