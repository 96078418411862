import React from 'react';
import { useMediaQuery } from './mediaQuery';
import BlazikenIcon from './images/icons/blaziken.png';
import Badge from './Badge';
import TSIcon from './images/icons/typescript.png';
import ReactIcon from './images/icons/react.png';
import TailwindIcon from './images/icons/tailwind-css.svg';

const containerStyle = (isSmallScreen) => {
  return {
    paddingTop: '1rem',
    paddingLeft: '8px',
    borderLeft: '1px solid #b1aebb',
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    justifyContent: 'space-between',
    gap: isSmallScreen ? '16px' : '8px',
  };
};

const projectStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}

const iconStyle = {
  height: '1rem',
  width: '1rem',
  marginRight: '4px',
}

const badgeContainer = {
  display: 'flex',
  gap: '8px',
}

export default function Projects(props) {
  const isSmallScreen = useMediaQuery('(max-width: 628px)');
  return (
    <div style={containerStyle(isSmallScreen)}>
      <div style={projectStyle}>
        <img style={iconStyle} src={BlazikenIcon} alt="Blaziken Icon"/>
        <a href="https://teambuilder.zains.dev" target="_blank" rel="noreferrer" style={{color: '#ff7c7c'}}>
          Pokemon-Team-Builder
        </a>
      </div>
      <div style={badgeContainer}>
          <Badge icon={TSIcon} text="TypeScript" color="royalblue" />
          <Badge icon={ReactIcon} text="React" color="lightblue" />
          <Badge icon={TailwindIcon} text="Tailwind" color="skyblue" />
      </div>
    </div>
  )
}
